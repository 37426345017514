// ImageZoomPlugin.js
export default {
    install(Vue, options = { zoomRate: 1.5 }) {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.addedNodes) {
                    mutation.addedNodes.forEach(node => {
                        if (node.nodeType === 1 && node.classList.contains('el-image-viewer__wrapper')) {
                            modifyZoomBehavior(node, options.zoomRate);
                        }
                    });
                }
            });
        });

        function modifyZoomBehavior(imageViewer, zoomRate) {
            const canvas = imageViewer.querySelector('.el-image-viewer__canvas');

            canvas.addEventListener('wheel', function(event) {
                event.stopPropagation(); // 停止事件冒泡
                event.preventDefault();  // 阻止默认行为
                let scale = parseFloat(canvas.style.transform.replace('scale(', '').replace(')', '')) || 1;
                if (event.deltaY < 0) {
                    scale *= zoomRate;
                } else {
                    scale /= zoomRate;
                }
                canvas.style.transform = `scale(${scale})`;
            }, { passive: false });
        }

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    }
}